import { Helmet } from 'react-helmet';
import { TITLES } from '../types/titles';
import ConfirmTwoFaLayout from '../components/login/confirm-two-fa';

const LoginConfirmTwoFa = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.LOGIN.TWOFA}</title>
			</Helmet>
			<ConfirmTwoFaLayout />
		</>
	);
};

export default LoginConfirmTwoFa;
