import { API_ROUTES } from '../types/api.routes';
import { ROUTES } from '../types/routes';
import { v4 as uuidv4 } from 'uuid';
import { customFetch } from '../utils';

export async function fetchRootPath(data: { wState: string }) {
	const wStateInfoData = await customFetch(
		API_ROUTES.COMMON.WSTATE_INFO.URL.replace('{wstate}', data.wState),
		{
			method: API_ROUTES.COMMON.WSTATE_INFO.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				'Content-Type': 'application/json'
			}
		}
	);

	const jsonWStateInfoData = await wStateInfoData.json();
	if (wStateInfoData.status != 200) {
		if (wStateInfoData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		throw jsonWStateInfoData;
	}
	sessionStorage.setItem('wStateInfo', JSON.stringify(jsonWStateInfoData));
	sessionStorage.setItem('f2xUserAgent', jsonWStateInfoData.f2xUserAgent);
	localStorage.setItem('theme', jsonWStateInfoData?.theme);

	const locale = window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';

	if (jsonWStateInfoData?.status?.toLocaleLowerCase() === 'done') {
		window.location.replace(
			ROUTES.REDIRECT.INDEX.replace(':locale', locale) +
				window.location.search
		);
	}

	switch (String(jsonWStateInfoData?.pageId)?.toLocaleLowerCase()) {
		case 'registrationpageid': {
			window.location.replace(
				ROUTES.REGISTRATION.INDEX.replace(':locale', locale) +
					window.location.search
			);
			break;
		}
		case 'paymentpageid': {
			window.location.replace(
				ROUTES.WITHDRAW.INDEX.replace(':locale', locale) +
					window.location.search
			);
			break;
		}
		case 'registrationeulaemailidentitypageid': {
			window.location.replace(
				ROUTES.REGISTRATION.INDEX.replace(':locale', locale) +
					window.location.search
			);
			break;
		}
		case 'registrationsamsubpageid': {
			window.location.replace(
				ROUTES.VERIFICATION.SUMSUB.replace(':locale', locale) +
					window.location.search
			);
			break;
		}
		case 'loginsamsubpageid': {
			window.location.replace(
				ROUTES.VERIFICATION.SUMSUB.replace(':locale', locale) +
					window.location.search
			);
			break;
		}
		case 'registrationphonepageid': {
			window.location.replace(
				ROUTES.REGISTRATION.PHONE.replace(':locale', locale) +
					window.location.search
			);
			break;
		}
		case 'registrationprofilepageid': {
			window.location.replace(
				ROUTES.VERIFICATION.INDEX.replace(':locale', locale) +
					window.location.search
			);
			break;
		}
		default: {
			window.location.replace(
				ROUTES.LOGIN.INDEX.replace(':locale', locale) +
					window.location.search
			);
			break;
		}
	}

	return jsonWStateInfoData;
}

export async function fetchWStateInfoRegistration(
	wState: string,
	isWaitPage?: boolean
) {
	const wStateInfoData = await customFetch(
		API_ROUTES.COMMON.WSTATE_INFO.URL.replace('{wstate}', wState),
		{
			method: API_ROUTES.COMMON.WSTATE_INFO.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				'Content-Type': 'application/json'
			}
		}
	);

	const jsonWStateInfoData = await wStateInfoData.json();
	if (wStateInfoData.status != 200) {
		if (wStateInfoData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		throw jsonWStateInfoData;
	}
	sessionStorage.setItem('wStateInfo', JSON.stringify(jsonWStateInfoData));
	sessionStorage.setItem('f2xUserAgent', jsonWStateInfoData.f2xUserAgent);
	localStorage.setItem('theme', jsonWStateInfoData?.theme)

	if (isWaitPage && jsonWStateInfoData.pageId !== 'registrationSamsubPageId') {
		if (jsonWStateInfoData.pageId === 'finishPageId') {
			window.location.replace(
				`/${window.location.pathname.split('/')[1]}/widget/finish`
			);
		}
	}

	return jsonWStateInfoData;
}
