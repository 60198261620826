import { Helmet } from 'react-helmet';
import { TITLES } from '../types/titles';
import SecretKeyLayout from '../components/login/secret-key';
import { QueryClient } from '@tanstack/react-query';
import { fetchLoginSecretKey } from '../api/login';

export const loginSecretKeyLoader = () => {
	return new QueryClient().fetchQuery({
		queryKey: ['loginSecretKey'],
		queryFn: () => fetchLoginSecretKey(),
		staleTime: Infinity
	});
};

const LoginSecretKey = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.LOGIN.SECRET_KEY}</title>
			</Helmet>
			<SecretKeyLayout />
		</>
	);
};

export default LoginSecretKey;
