import SumsubWebSdk from '@sumsub/websdk-react';
import { useContext, useEffect, useState } from 'react';
import { CustomButton } from '../../common/buttons';
import { useSumsubClearMutation } from '../../../data/mutations/verification';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { useTranslation } from 'react-i18next';
import { ConfirmRegistrationModal } from '../../common/modals';
import { ROUTES } from '../../../types/routes';
import { LocaleContext } from '../../../data/locale.provider';

const VerificationSumSubLayout = () => {
	const { locale } = useContext(LocaleContext);
	const [sumSubAccessToken, setSumSubAccessToken] = useState<string>('');

	const { t } = useTranslation();

	const navigate = useCustomNavigate();

	const { isError, isPending, mutateAsync, error } = useSumsubClearMutation(navigate);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

	const [isDesktop, setIsDesktop] = useState<boolean>(false);

	const [isClearButtonHidden, setIsClearButtonHidden] = useState<boolean>(false);

	useEffect(() => {
		const sumsubInfo = JSON.parse(sessionStorage.getItem('sumsubInfo') as string);
		if (sumsubInfo) {
			setSumSubAccessToken(sumsubInfo?.widgetConfiguration?.accessToken);
		}
		if (window.innerWidth >= 481) {
			setIsDesktop(true);
		}
		const wStateInfo = JSON.parse(sessionStorage.getItem('wStateInfo') as string);
		if(wStateInfo) {
			if(wStateInfo.pageId === 'loginSamsubPageId') {
				setIsClearButtonHidden(true);
			}
		}
	}, []);

	useEffect(() => {
		if (isError) {
			setIsLoading(false);
		}
	},[isError]);

	useEffect(() => {
		if (isPending) {
			setIsLoading(isPending);
		}
	}, [isPending]);

	const expirationHandler = () => {
		console.log('');
	};

	//eslint-disable-next-line
	const sumSubMessageHandler = (type: string, payload: any) => {
		console.log('messageType', type);
		console.log('payload', payload);
		if (
			type === 'idCheck.onApplicantStatusChanged' &&
			payload?.reviewResult &&
			payload?.reviewResult?.reviewAnswer === 'GREEN'
		) {
			navigate(ROUTES.VERIFICATION.WAIT);
		}
	};

	if (sumSubAccessToken === '') {
		return <></>;
	}

	const handleIsConfirmModalOpen = () => {
		setIsConfirmModalOpen(!isConfirmModalOpen);
	};

	const handleBackClick = async () => {
		sessionStorage.clear();
		await mutateAsync();
	};

	return (
		<div
			className={'component'}
			style={isDesktop ? {
				bottom: 'auto',
				marginTop: '64px'
			} : {}}
		>
			<ConfirmRegistrationModal
				isLoading={isLoading}
				isModalOpen={isConfirmModalOpen}
				handleIsModalOpen={handleIsConfirmModalOpen}
				handleConfirm={handleBackClick}
				text={t('startRegistrationAgainMain') as string}
			/>
			<SumsubWebSdk
				config={{
					lang: locale,
				}}
				accessToken={sumSubAccessToken}
				onMessage={sumSubMessageHandler}
				expirationHandler={expirationHandler}
			/>
			{isError ? (
				<h1 className={'registrationEmailError'}>
					{
						(error as any)?.message //eslint-disable-line @typescript-eslint/no-explicit-any
					}
				</h1>
			) : (
				''
			)}
			{!isClearButtonHidden ? <CustomButton
				isText={true}
				text={t('startRegistrationAgain') as string}
				onClick={handleIsConfirmModalOpen}
				className={'startButton'}
			/> : ''}
		</div>
	);
};

export default VerificationSumSubLayout;
