import { CircularProgress } from '@mui/material';

interface Props {
	thickness: number;
    className: string;
	size?: number;
}
const CircleProgress = ({thickness, size, className}: Props) => {
	return (
		<div className={className}>
			<svg width='40' height='40'>
				<linearGradient id='linearColors' x1='0' y1='0' x2='1' y2='1'>
					<stop offset='0%' stopColor={'#FFFFFF'} />
					<stop offset='5%' stopColor='#00BEB4' />
					<stop offset='95%' stopColor={'#FFFFFF'} />
					<stop offset='100%' stopColor={'#FFFFFF'} />
				</linearGradient>
			</svg>
			<CircularProgress
				className={'circle-position'}
				sx={{
					'.MuiCircularProgress-circle': {
						stroke: 'url(#linearColors)'
					}
				}}
				thickness={thickness}
				size={size}
			/>
		</div>
	);
};

export {CircleProgress};
