import { Helmet } from 'react-helmet';
import { TITLES } from '../types/titles';
import SetupTwoFaLayout from '../components/login/setup-two-fa';

const LoginSetupTwoFa = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.LOGIN.SETUP}</title>
			</Helmet>
			<SetupTwoFaLayout />
		</>
	);
};

export default LoginSetupTwoFa;
