import { Helmet } from 'react-helmet';
import { TITLES } from '../types/titles';
import WaitLayout from '../components/verification/wait';

/*export const verificationWaitLoader = () => {
	const wState = new URLSearchParams(window.location.search).get(
		'wstate'
	) as string;

	return new QueryClient().fetchQuery({
		queryKey: ['verificationWaitSumSub'],
		queryFn: () => fetchWStateInfoRegistration(wState, true),
		staleTime: 3000,
	});
};*/

const VerificationWait = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.VERIFICATION.WAIT}</title>
			</Helmet>
			<WaitLayout />
		</>
	);
};

export default VerificationWait;
