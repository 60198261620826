import { API_ROUTES } from '../types/api.routes';
import { ROUTES } from '../types/routes';
import { v4 as uuidv4 } from 'uuid';
import { customFetch } from '../utils';
import { TAnswers, TSubmitVerificationBody } from '../types/types';
import { fetchWStateInfoRegistration } from './common';

export async function fetchSumSubInfo() {
	const wState = new URLSearchParams(window.location.search).get(
		'wstate'
	) as string;

	await fetchWStateInfoRegistration(wState);

	const sumsubInfoData = await customFetch(
		API_ROUTES.REGISTRATION.SUMSUB_INFO.URL +
			'?' +
			new URLSearchParams({
				wstate: wState
			}),
		{
			method: API_ROUTES.REGISTRATION.SUMSUB_INFO.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				'Content-Type': 'application/json'
			}
		}
	);

	const jsonSumsubInfoData = await sumsubInfoData.json();

	if (sumsubInfoData.status != 200) {
		if (sumsubInfoData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		throw jsonSumsubInfoData;
	}

	if (
		sumsubInfoData.status === 200 &&
		jsonSumsubInfoData?.nextPageId === 'finishPageId'
	) {
		window.location.replace(
			`/${window.location.pathname.split('/')[1]}/widget/finish`
		);
	}

	sessionStorage.setItem('sumsubInfo', JSON.stringify(jsonSumsubInfoData));

	return jsonSumsubInfoData;
}

export async function fetchVerificationSubmit(data: TSubmitVerificationBody) {
	const verificationData = await customFetch(
		API_ROUTES.REGISTRATION.PROFILE.URL,
		{
			method: API_ROUTES.REGISTRATION.PROFILE.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				wstate: data.wState,
				firstName: data.firstName,
				middleName: data.middleName,
				lastName: data.lastName,
				dateOfBirth: data.dateOfBirth.replaceAll('.', ''),
				citizenship: data.citizenship
			})
		}
	);

	const verificationDataJson = await verificationData.json();
	if (verificationData.status != 200) {
		if (verificationData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		throw verificationDataJson;
	}

	return verificationDataJson;
}

export async function fetchQuestionary() {
	const wState = new URLSearchParams(window.location.search).get(
		'wstate'
	) as string;

	const questionaryData = await customFetch(
		API_ROUTES.REGISTRATION.GET_QUESTIONARY.URL +
			'?' +
			new URLSearchParams({
				wstate: wState
			}),
		{
			method: API_ROUTES.REGISTRATION.GET_QUESTIONARY.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				'Content-Type': 'application/json'
			}
		}
	);

	const jsonQuestionaryData = await questionaryData.json();

	if (questionaryData.status != 200) {
		if (questionaryData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		throw jsonQuestionaryData;
	}

	sessionStorage.setItem('questionary', JSON.stringify(jsonQuestionaryData));

	return jsonQuestionaryData;
}

export async function fetchSubmitQuestionaryTest(data: {
	wstate: string;
	questions: TAnswers;
}) {
	const wState = new URLSearchParams(window.location.search).get(
		'wstate'
	) as string;

	const questionaryData = await customFetch(
		API_ROUTES.REGISTRATION.SEND_QUESTIONARY.URL +
			'?' +
			new URLSearchParams({
				wstate: wState
			}),
		{
			method: API_ROUTES.REGISTRATION.SEND_QUESTIONARY.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		}
	);

	const jsonQuestionaryData = await questionaryData.json();

	if (questionaryData.status != 200) {
		if (questionaryData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		throw jsonQuestionaryData;
	}

	return jsonQuestionaryData;
}
